import React from "react";
import { graphql } from 'gatsby';
import Service from '../../components/Services/Service';
import MazeIcon from '/src/assets/servicesIcons/maze_white.svg';
import { Text } from "../../components/Common";
import { useBreadcrumbsNesteadSchema } from '../../hooks/useBreadcrumbsNesteadSchema';
import { SERVICES_ORIGINAL_BREADCRUMBS } from '../../constants';

const SaaSConsultingPage = () => {
  const breadcrumbsElementsSchema = useBreadcrumbsNesteadSchema("SaaS Consulting", SERVICES_ORIGINAL_BREADCRUMBS);

  return (
    <Service
      header="services page saaS web applications consulting"
      seo={{
        title: 'SEO title service front saas consulting',
        description: 'SEO description service saas consulting',
        breadcrumbsElementsSchema
      }}
      Icon={MazeIcon}
      width="34vw"
    >
      <>
        <Text text="SaaS Web Applications Consulting Description1" variant="body1" component="p" />
        <br />
        <Text text="SaaS Web Applications Consulting Description2" variant="body1" component="p" />
        <br />
        <Text text="SaaS Web Applications Consulting Description3" variant="body1" component="p" />
      </>
    </Service>
  );
}

export default SaaSConsultingPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
